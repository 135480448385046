// Header
export const LOGO_TEXT = 'TOM-DAR';

// Main page
export const PAGE_TITLE = 'meble skrojone na miarę';
export const OUR_OFFER = 'Nasza oferta';
export const HEADER_BUTTONS_1 = 'Zobacz nasze kuchnie';
export const HEADER_BUTTONS_2 = 'Szafy';
export const HEADER_BUTTONS_3 = 'Łazienki';
export const HEADER_BUTTONS_4 = 'Biura';

export const FIRST_CONTENT_TITLE = 'Meble na wymiar Trójmiasto';
export const FIRST_CONTENT_DESCRIPTION = 'Tom-dar wykonuje meble na wymiar z najwyższą starannością i dbałością o szczegóły. Mamy 25 lat doświadczenia w wykonywaniu usług stolarki meblowej. Pracujemy na najwyższej jakości materiałach i podzespołach. Oferujemy krótki termin realizacji, konkurencyjne ceny oraz kompleksowe doradztwo poparte wieloletnim doświadczeniem w branży. Specjalizujemy się w wykonywaniu kuchni na wymiar, szaf pod zabudowę, mebli łazienkowych oraz mebli do biur. Usługi wykonujemy na terenie Trójmiasta i okolic, lecz odległość nie jest dla nas przeszkodą.';
export const SECOND_CONTENT = 'Realizacje naszych mebli';

export const COMPONENTS_TITLE = 'Podzespoły najwyższej jakości';
export const COMPONENTS_DESCRIPTION = 'Pracujemy na najwyższej jakości podzespołach marki Blum. Jest producentem wysokiej jakości okuć meblowych, innowacyjnych systemów zawiasów, szuflad oraz podnośników do frontów górnych. Zapewniają wysoki komfort użytkowania naszych mebli.';

// Realization steps
export const REALIZATION_STEPS_TITLE = '3 kroki do Twojej realizacji';
export const STEP_1_TITLE = 'Kontakt z nami';
export const STEP_1_DESCRIPTION = 'Skontakuj się z nami, aby umówić się na rozmowę wstępną oraz pomiar';
export const STEP_2_TITLE = 'Bezpłatny pomiar oraz projekt';
export const STEP_2_DESCRIPTION = 'Wykonujemy pomiar, wstępny projekt realizacji oraz przedstawiamy wycenę'
export const STEP_3_TITLE = 'Transport i montaż';
export const STEP_3_DESCRIPTION = 'Zapewniamy kompleksową usługę montażu mebli zgodnie z oczekiwaniami klienta oraz projektem'

// Form
export const FROM_TITLE = 'Umów się na bezpłatny pomiar';
export const FORM_LABEL_NAME = 'Imię i nazwisko';
export const FORM_LABEL_EMAIL = 'Email';
export const FORM_LABEL_PHONE = 'Numer telefonu';
export const FORM_LABEL_MESSAGE = 'Wiadomość';
export const FORM_SUBMIT = 'Wyślij'

// Footer
export const ABOUT_US_FOOTER_TITLE = 'Krótko o nas';
export const ABOUT_US_FOOTER_TEXT = 'Wykonujemy meble na wymiar z najwyższą starannością i dbałością o szczegóły. Mamy 25 lat doświadczenia w wykonywaniu usług stolarki meblowej.';
export const CONTACT = {
    title: 'Kontakt',
    companyName: 'Tom-dar Tomasz Hinca',
    address: 'ul. Aleja Lipowa 28/1',
    city: '80-298 Leźno',
    phone: '+48 602-389-020',
    mail: 'tom-dar1974@wp.pl',
    nip: '589-11-11-524',
    regon: '367134377',

};

export const MAP_PAGE = {
    title: 'Mapa strony',
    items: [
        { content: 'Start', to: '/'},
        { content: 'Realizacje', to: '/realizacje'},
        { content: 'Oferta', to: '/oferta'},
        { content: 'Kontakt', to: '/kontakt'},
    ]
};

export const MADE_BY = 'Wykonanie';

// Realizations
export const REALIZATIONS = 'Realizacje';




// Offer
export const OFFER = 'Oferta';
export const OFFER_TITLE = 'Meble na wymiar';
export const OFFER_MAIN_CONTENT = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.';



// Contact
export const DATA_CONTACT = 'Dane kontaktowe';
export const DATA_COMPANY = 'Dane firmy';
export const NIP = 'NIP';
export const REGON = 'REGON';


