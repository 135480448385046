const options = {
    settings: {
        disablePanzoom: true,
    },
    buttons: {
        showDownloadButton: false,
        showFullscreenButton: false,
        showThumbnailsButton: false,
        showAutoplayButton: false,
    },
    caption: {
        showCaption: false,
    },
}

export default options;