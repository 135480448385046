import React from 'react';
import {Bar, Container, Description, Title} from "./TextContent.style";

const TextContent = ({title, description, variant}) => {

    return (
        <Container variant={variant}>
            <Title variant={variant}>{title}</Title>
            <Bar />
            <Description>{description}</Description>
        </Container>
    );
};

export default TextContent;
