import React from "react";
import { ReactSVG } from 'react-svg'
import {CircleWrapper, Description, RealizationContainer, Title} from "./RealizationTile.style";


const RealizationTile = ({img, title, description}) => {
    return (
        <RealizationContainer>
            <CircleWrapper>
                <ReactSVG
                    beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'width: 50%')
                    }}
                    src={img}

                />
            </CircleWrapper>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </RealizationContainer>
    );
};

export default RealizationTile;