import styled from 'styled-components';
import theme from "../../utils/theme";

export const RealizationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 25%;
    margin-top: ${theme.spacing.sm}px;

    @media (${theme.device.laptop}) {
        width: 28%;
    }

    @media (${theme.device.mobileL}) {
        width: 90%;
        margin: ${theme.spacing.xl}px 0;
    }
`;

export const CircleWrapper = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: ${theme.colors.main};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    & .svg-class-name {
        width: 20%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
  
    @media (${theme.device.laptop}) {
      width: 100px;
      height: 100px;
    }

`;

export const Title = styled.h2`
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.semiBold};
    font-size: ${theme.fontSize.m}px;
    color: ${theme.colors.foreground};
    text-align: center;
    margin: ${theme.spacing.xl}px 0;

    @media (${theme.device.laptop}) {
        font-size: ${theme.fontSize.s}px;
        margin: ${theme.spacing.xs}px 0;
    }
`;

export const Description = styled.p`
    font-family: ${theme.fonts.main};
    font-size: ${theme.fontSize.xs}px;
    color: ${theme.colors.foreground};
    text-align: center;
`;