import styled from 'styled-components';
import theme from "../../utils/theme";


export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 80%;
    position: relative;
  
    @media (${theme.device.laptop}) {
        width: 100%;
    }
`;

export const Square = styled.div`
    position: absolute;
    bottom: -4%;
    left: 7%;
    width: 6vw;
    height: 6vw;
    border: 6px solid ${theme.colors.foreground};
`;

export const Buttons = styled.div`
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: row;
    width: 70%;
    height: 5vw;
    max-height: 70px;
    max-width: 600px;
    bottom: -4%;
    right: 5%;
    cursor: pointer;
    user-select: none;

    @media (${theme.device.laptopL}) {
        height: 4vw;
    }
`;

export const ImageWrapper = styled.div`
    width: 90%;
    z-index: 1;
    position: relative;
`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.2);
`;

export const ImageHeader = styled.img`
    width: 100%;
`;

export const RootButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RootButtonText = styled.div`
    font-family: ${theme.fonts.main};
    font-size: ${theme.fontSize.xs}px;
    font-weight: ${theme.fontWeight.semiBold};
    text-transform: uppercase;
    text-align: center;

    @media (${theme.device.laptopL}) {
        font-size: ${theme.fontSize.xs/1.5}px;
    }

    @media (${theme.device.tablet}) {  
        font-size: ${theme.fontSize.xs/2}px;
    }
`;

export const MainButtonContainer = styled(RootButtonContainer)`
    background-color: ${theme.colors.foreground};
    width: 40%;
    padding: ${theme.spacing.xs}px;
`;

export const MainButtonText = styled(RootButtonText)`
    color: ${theme.colors.background};
    

`

export const OtherButtonContainer = styled(RootButtonContainer)`
    background-color: ${theme.colors.background};
    width: 20%;
    border-left: 1px solid ${theme.colors.foreground};
`;

export const OtherButtonText = styled(RootButtonText)`
    color: ${theme.colors.foreground};

`
