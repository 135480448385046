import React from 'react';
import styled from "styled-components";
import theme from "../../utils/theme";


const FooterTile = ({title, children}) => {

    return (
        <FooterTileContainer>
            <Title>{title}</Title>
            <Bar />
            {children}
        </FooterTileContainer>
    );
};

export default FooterTile;


const FooterTileContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 300px;

    @media (${theme.device.tablet}) {
        max-width: 150px;
    }

    @media (${theme.device.mobileL}) {
        width: 90%;
        max-width: none;
        margin-top: ${theme.spacing.xl}px
    }
`;

const Title = styled.h3`
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.semiBold};
    color: ${theme.colors.foreground};
    font-size: ${theme.fontSize.s}px;

    @media (${theme.device.laptop}) {
        font-size: ${theme.fontSize.xs}px;
    }
`;

const Bar = styled.div`
    width: 70px;
    height: 5px;
    background-color: ${theme.colors.main};
    margin: ${theme.spacing.xs}px 0;
`;