import styled from 'styled-components';
import theme from '../../utils/theme';



export const MainPageContainer = styled.div`
  
`;

export const ContentMargin = styled.div`
    margin-top: 200px;
    margin-bottom: 200px;

    @media (${theme.device.laptop}) {
        margin-top: 100px;
        margin-bottom: 100px;
    }
`

export const FirstMobileContent = styled.section`
    display: flex;
    width: 100%;
    height: calc(100vh - 100px);
    background-color: ${theme.colors.main};
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`;

export const MobileImage = styled.img`
    height: 100%;
    width: auto;
    
    @media (${theme.device.mobileL}) {
        width: 150%;
        height: auto;
    }
`;

export const MobileContentWrapper = styled.div`
    width: 60%;
    max-width: 250px;
    background-color: ${theme.colors.background};
    padding: ${theme.spacing.xl}px;
    transform: translateY(-130%);

    @media (${theme.device.mobileL}) {
        padding: ${theme.spacing.sm}px;
        width: 90%;
        transform: translateY(-50%);
        max-width: none;
    }
`;

export const FirstContent = styled.section`
    height: calc(100vh - 100px);
    display: flex;
`;

export const LeftSide = styled.aside`
    flex-basis: 30%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (${theme.device.laptop}) {
        flex-basis: 40%;
    }
`;

export const LeftSideWrapper = styled.div`
    width: 280px;
    position: relative;

    @media (${theme.device.laptop}) {
        width: 230px;
    }

    @media (${theme.device.tablet}) {
        width: 170px;
    }
`;

export const LeftSideText = styled.div`
    font-size: ${theme.fontSize.xl}px;
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.foreground};
    margin-bottom: ${theme.spacing.xl}px;
    
    @media (${theme.device.laptop}) {
        font-size: ${theme.fontSize.l}px;
    }

    @media (${theme.device.tablet}) {
        font-size: ${theme.fontSize.m}px;
    }
`;

export const Underline = styled.div`
    position: absolute;
    width: 200px;
    height: 12px;
    background-color: ${theme.colors.main};
    top: 50px;
    left: 0;
    z-index: -1;

    @media (${theme.device.laptop}) {
        top: 40px;
        height: 8px;
        width: 160px;
    }

    @media (${theme.device.tablet}) {
        top: 60px;
        left: 30px;
        height: 6px;
        width: 118px;
    }

    @media (${theme.device.mobileL}) {
        top: 45px;
        left: 15px;
        height: 6px;
        width: 118px;
    }
`;


export const RightSide = styled.aside`
    flex-basis: 70%;
    background-color: ${theme.colors.main};
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (${theme.device.laptop}) {
        flex-basis: 60%;
    }    
`;

export const GalleryContainer = styled.section`
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
    
  &:first-child {
    display: flex;
  }
  
  
`;

export const GalleryImages = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  
    img {
      filter: brightness(100%);
      transition: filter 0.3s;
    }
  
    img:hover {
      filter: brightness(70%);
    }

`;

export const ComponentsContent = styled.section`
    margin-top: 200px;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (${theme.device.laptop}) {
        margin-top: 100px;
        margin-bottom: 100px;
    }
`;

export const BlumImage = styled.img`
    width: 20%;
    margin: 80px 0;
    min-width: 200px;

    @media (${theme.device.mobileL}) {
        margin: 40px 0;
    }
`;

export const RowImagesContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 70%;
  
    @media (${theme.device.mobileL}) {
        width: 95%;
    }
  
    @media (${theme.device.mobileL}) {
        width: 100%;
    }  
`;

export const ComponentsImage = styled.img`
    width: 45%;
`;

export const RealizationsContent = styled.div`
    margin-top: 200px;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    @media (${theme.device.laptop}) {
        margin-top: 100px;
        margin-bottom: 100px;
    }
`;

export const RealizationSteps = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

    @media (${theme.device.laptop}) {
        flex-direction: column;
        align-items: center;
    }
`;