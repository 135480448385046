import styled from 'styled-components';
import theme from "../../utils/theme";

export const ContactContainer = styled.div`
    
`;

export const Wrapper = styled.div`
    padding: ${theme.spacing.xl}px 0;
    margin: 100px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    background-color: ${theme.colors.secondary};

    @media (${theme.device.tablet}) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Tile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  
    @media (${theme.device.tablet}) {
        margin: ${theme.spacing.sm}px 0;
    }
`;

export const DataTitle = styled.h2`
    font-size: ${theme.fontSize.m}px;
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.semiBold};
    color: ${theme.colors.foreground};
`;

export const Bar = styled.div`
    width: 100px;
    height: 6px;
    background-color: ${theme.colors.main};
    margin: ${theme.spacing.xl}px 0;
`;

export const DataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DataRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: ${theme.spacing.sm}px;
`;

export const DataTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const DataText = styled.p`
    text-align: center;
    font-size: ${theme.fontSize.xs}px;
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.semiBold};
    color: ${theme.colors.foreground};
`;

export const AddressWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;


export const MapContainer = styled.div`
    max-width: 1000px;
    width: 80%;
    margin: 100px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (${theme.device.mobileL}) {
        width: 95%;
    }
`;

export const SubmitButton = styled.button`
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: ${theme.fonts.main};
    transform: translate(${props => !props.send ? '5px' : '0px, 2.5px'});
    font-size: ${theme.fontSize.s}px;
    font-weight: ${theme.fontWeight.semiBold};
    color: ${theme.colors.background};
    background-color: ${props => !props.send ? theme.colors.main : theme.colors.foreground};
    box-shadow: ${props => props.send ? '0 0 0 0 white' : '-5px 5px 0 0 white'};
    padding: 15px;
    text-align: center;
    transition: 0.3s;
    
    &:hover {
        box-shadow: 0 0 0 0 ${theme.colors.secondary};
        transform: translate(0px, 2.5px);
    }

    @media (${theme.device.tablet}) {
        font-size: ${theme.fontSize.xs}px;
    }
`;