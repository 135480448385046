import React from 'react';
import {Container, LogoWrapper, Phone, RightSide, RightSideWrapper} from "./Header.style";
import Logo from "../Logo/Logo";
import Nav from "../Nav/Nav";
import {CONTACT} from "../../utils/labels";



const Header = () => {

    return (
        <Container>
            <LogoWrapper>
                <Logo />
            </LogoWrapper>
            <RightSide>
                <RightSideWrapper>
                    <Nav />
                    <Phone href={`tel: ${CONTACT.phone}`}>{CONTACT.phone}</Phone>
                </RightSideWrapper>
            </RightSide>
        </Container>
    )
}

export default Header;
