import React from "react";
import {
    ContentWrapper,
    Copyright,
    DescriptionText,
    DescriptionTextStrong,
    FooterContainer, LinkTo,
    LogoContainer, MadeBy, MapContainer,
    Margin, NavText, Wrapper
} from "./Footer.style";
import Logo from "../Logo/Logo";
import FooterTile from "./FooterTile";
import {ABOUT_US_FOOTER_TEXT, ABOUT_US_FOOTER_TITLE, CONTACT, MADE_BY, MAP_PAGE} from "../../utils/labels";
import Button from "../Button/Button";



const Footer = () => {

    const map = MAP_PAGE.items.map((item, index) => (
        <div key={index}>
            <Button
                variant={'regular'}
                to={item.to}
            >
                <NavText>{item.content}</NavText>
            </Button>
            <Margin/>
        </div>
    ))

    return (
        <FooterContainer>
            <Wrapper>
                <LogoContainer>
                    <Logo variant={'smaller'} />
                </LogoContainer>

                <ContentWrapper>
                    <FooterTile title={ABOUT_US_FOOTER_TITLE}>
                        <DescriptionText>
                            {ABOUT_US_FOOTER_TEXT}
                        </DescriptionText>
                    </FooterTile>
                    <FooterTile title={CONTACT.title}>
                        <DescriptionTextStrong>{CONTACT.companyName}</DescriptionTextStrong>
                        <DescriptionText>{CONTACT.address}</DescriptionText>
                        <DescriptionText>{CONTACT.city}</DescriptionText>
                        <Margin />
                        <DescriptionText>{'Nr tel.: '}{CONTACT.phone}</DescriptionText>
                        <DescriptionText>{'E-mail: '}{CONTACT.mail}</DescriptionText>
                    </FooterTile>
                    <FooterTile title={MAP_PAGE.title}>
                        <MapContainer>
                            {map}
                        </MapContainer>
                    </FooterTile>
                </ContentWrapper>
            </Wrapper>
            <Copyright>
                Copyright by Tom-dar Tomasz Hinca
            </Copyright>
            <MadeBy>
                {MADE_BY}{' '}
                <LinkTo href={'https://github.com/adi52'}> bielinski.dev</LinkTo>
            </MadeBy>
        </FooterContainer>
    );
};

export default Footer;