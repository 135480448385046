import styled from "styled-components";
import theme from "../../utils/theme";


export const Content = styled.div`
    margin: 50px auto;
    max-width: 1000px;
    width: 80%;
`;

export const ContentText = styled.p`
    text-align: center;
    margin-top: ${theme.spacing.xs}px;
    font-size: ${theme.fontSize.sm}px;
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.regular};
    color: ${theme.colors.foreground};
`;

export const OfferItemsContainer = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 85%;
    margin: 50px auto 0 auto;
    justify-content: center;
`;

export const OfferItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px 50px 10px;
`;

export const ImageContainer = styled.div`
    width: 100%;
    position: relative;
`;

export const OfferItemImage = styled.img`
    height: 100%;
    width: 100%;
`;

export const Overlay = styled.div`
    background-color: rgba(0,0,0,0);
    position: absolute;
    width: 100%;
    height: 100%;
    transition: 0.3s;
    cursor: pointer;

  &:hover {
      background-color: rgba(0,0,0,0.3);
    }
`;

export const OfferItemTitle = styled.h3`
    margin-top: ${theme.spacing.xs}px;
    font-size: ${theme.fontSize.sm}px;
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.semiBold};
    color: ${theme.colors.main};
`;