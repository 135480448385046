const spacing = {
    xs: 10,
    sm: 15,
    xl: 30,
}

const fonts = {
    main: "'Montserrat', sans-serif",
}

const fontWeight = {
    bold: 700,
    semiBold: 600,
    regular: 400,
}

const fontSize = {
    xs: 18,
    s: 24,
    m: 36,
    l: 48,
    xl: 60,
}

// RWD
const size = {
    mobileS: '321px',
    mobileM: '376px',
    mobileL: '426px',
    tablet: '769px',
    laptop: '1025px',
    laptopL: '1441px',
    desktop: '2561px'
}

const device = {
    mobileS: `max-width: ${size.mobileS}`,
    mobileM: `max-width: ${size.mobileM}`,
    mobileL: `max-width: ${size.mobileL}`,
    tablet: `max-width: ${size.tablet}`,
    laptop: `max-width: ${size.laptop}`,
    laptopL: `max-width: ${size.laptopL}`,
    desktop: `max-width: ${size.desktop}`,
};
// END RWD

const colors = {
    main: '#3C6E71',
    secondary: '#D9D9D9',
    background: '#ffffff',
    foreground: '#353535',
}

const theme = {
    size: size,
    device: device,
    spacing: spacing,
    fonts: fonts,
    fontSize: fontSize,
    fontWeight: fontWeight,
    colors: colors,
}

export default theme;
