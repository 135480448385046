import styled from 'styled-components';
import theme from '../../utils/theme';


export const Container = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
`

export const LogoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const RightSide = styled.div`
    background-color: ${theme.colors.main};
    flex-basis: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (${theme.device.laptop}) {
        flex-basis: 60%;
    }
    
`;

export const RightSideWrapper = styled.div`
    width: 100%;
    margin-left: 60px;
    margin-right: ${theme.spacing.xl}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const Phone = styled.a`
    font-size: ${theme.fontSize.s}px;
    color: ${theme.colors.background};
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.bold};
    text-decoration: none;

    @media (${theme.device.laptopL}) {
        font-size: ${theme.fontSize.xs}px;
    }
`;
