import styled from 'styled-components';
import theme from "../../utils/theme";

export const RootButton = styled.div`
    cursor: pointer;
    font-family: ${theme.fonts.main};
`

export const PrimaryButton = styled(RootButton)`
    //width: calc(100% - 5px);
    transform: translate(5px);
    font-size: ${theme.fontSize.s}px;
    font-weight: ${theme.fontWeight.semiBold};
    color: ${theme.colors.background};
    background-color: ${theme.colors.main};
    box-shadow: -5px 5px 0 0 ${theme.colors.secondary};
    padding: 15px;
    text-align: center;
    transition: 0.3s;
    
    &:hover {
        box-shadow: 0 0 0 0 ${theme.colors.secondary};
        transform: translate(0px, 2.5px);
    }

    @media (${theme.device.tablet}) {
        font-size: ${theme.fontSize.xs}px;
    }
`;

export const PrimaryWhiteButton = styled(PrimaryButton)`
  box-shadow: -5px 5px 0 0 ${theme.colors.background};
`;

export const PrimaryGrayButton = styled(PrimaryButton)`
  box-shadow: -5px 5px 0 0 ${theme.colors.secondary};
`;

export const RegularButton = styled(RootButton)`
  

`;
