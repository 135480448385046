import React from 'react';
import styled from 'styled-components';
import theme from "../../utils/theme";

import headerImg from '../../assets/images/header-img.jpg';

const HeaderBackgroundImage = ({title}) => {
    return (
        <Container>
            <HeaderText>{title}</HeaderText>
            <Overlay />
        </Container>
    );
};

export default HeaderBackgroundImage;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${headerImg});
    background-attachment: fixed;
    background-position: bottom center;
    position: relative;

    @media (${theme.device.laptop}) {
        background-position: center -200px;
    }

    @media (${theme.device.tablet}) {
        height: 200px;
        background-position: center -220px;
    }
`;

const HeaderText = styled.h1`
    font-family: ${theme.fonts.main};
    font-size: ${theme.fontSize.xl*1.5}px;
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.background};
    z-index: 2;
  
    @media (${theme.device.tablet}) {
        font-size: ${theme.fontSize.xl}px;
    }

    @media (${theme.device.tablet}) {
        font-size: ${theme.fontSize.xl}px;
    }

    @media (${theme.device.mobileM}) {
        font-size: ${theme.fontSize.l}px;
    }
`;


const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
`;