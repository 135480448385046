import React, {useEffect} from 'react';
import {OFFER, OFFER_MAIN_CONTENT} from "../../utils/labels";
import HeaderBackgroundImage from "../../components/HeaderBackgroundImage/HeaderBackgroundImage";
import ContactForm from "../../components/ContactForm/ContactForm";

import {
    Content, ContentText,
    ImageContainer,
    OfferItem,
    OfferItemImage,
    OfferItemsContainer,
    OfferItemTitle,
    Overlay
} from "./OfferPage.style";

import T1 from '../../assets/images/thumbnails/15.jpg';
import T2 from '../../assets/images/thumbnails/4.jpg';
import T3 from '../../assets/images/thumbnails/8.jpg';
import T4 from '../../assets/images/thumbnails/58.jpg';
import T5 from '../../assets/images/thumbnails/1.jpg';
import T6 from '../../assets/images/thumbnails/7.jpg';


const offerItems = [
    {id: 1, title: 'Kuchnie', img: T1},
    {id: 2, title: 'Szafy pod zabudowę', img: T2},
    {id: 3, title: 'Szafy wolnostojące', img: T3},
    {id: 4, title: 'Garderoby', img: T4},
    {id: 5, title: 'Meble łazienkowe', img: T5},
    {id: 6, title: 'Meblościanki', img: T6},
]

const Item = ({item}) => {
    return (
        <OfferItem>
            <ImageContainer>
                <Overlay />
                <OfferItemImage src={item.img} />
            </ImageContainer>
            <OfferItemTitle>{item.title}</OfferItemTitle>
        </OfferItem>
    )
}


const OfferPage = () => {
    useEffect(() => {
        document.title = 'Tom-dar - Oferta | Meble skrojone na miarę | Trójmiasto';
    }, [])

    const items = offerItems.map(item => <Item item={item} key={item.id}/>);

    return (
        <>
            <HeaderBackgroundImage title={OFFER} />
            <OfferItemsContainer>
                {items}
            </OfferItemsContainer>
            <ContactForm />
        </>
    )
}

export default OfferPage;
