import styled from 'styled-components';
import theme from '../../utils/theme';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: ${theme.spacing.xl}px;
    
    &:hover {
      cursor: pointer;
    }
`;


export const LogoImage = styled.img`
    width: ${props => props.variant === 'smaller' ? 70/1.5 : 70}px;
    height: ${props => props.variant === 'smaller' ? 40/1.5 : 40}px;
    margin-right: ${theme.spacing.sm}px;

    @media (${theme.device.laptop}) {
        width: ${props => props.variant === 'smaller' ? 70/2 : 70/1.5}px;
        height: ${props => props.variant === 'smaller' ? 40/2 : 40/1.5}px;
    }
`;

export const LogoText = styled.h1`
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${props => props.variant === 'smaller' ? theme.fontSize.s : theme.fontSize.m}px;
    color: ${theme.colors.foreground};
    text-transform: uppercase;
  
    @media (${theme.device.laptop}) {
        font-size: ${props => props.variant === 'smaller' ? theme.fontSize.s/1.5 : theme.fontSize.m/1.5}px;
    }
`;
