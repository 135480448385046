import styled from 'styled-components';
import theme from "../../utils/theme";

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.secondary};
    padding: ${theme.spacing.xl}px 0;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
  
    @media (${theme.device.mobileL}) {
        width: 95%;
    }
`;

export const LeftSide = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (${theme.device.tablet}) {
        width: 0;
        display: none;
    }
`;

export const ContactImage = styled.img`
    width: 80%;  
`;

export const RightSide = styled.div`
    width: 50%;
    padding: 0 ${theme.spacing.xl*2}px;

    @media (${theme.device.tablet}) {
        width: 100%;
    }

    @media (${theme.device.mobileL}) {
        padding: 0 ${theme.spacing.sm}px;
    }
`;

export const FormWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

