import styled from 'styled-components';
import theme from "../../utils/theme";

export const Container = styled.header`
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.background};
    position: relative;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
`;

export const Phone = styled.a`
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;  
    background-color: ${theme.colors.main};
    color: ${theme.colors.background};
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.fontSize.xs / 1.5}px;
    padding: 15px;
    text-decoration: none;
`;

export const SideMenu = styled.div`
    position: fixed;
    height: calc(100vh - 100px);
    width: 100%;
    top: 100px;
    left: 0;
    transform: ${props => !props.active ? 'translateX(100%)' : 'translateX(0)'};
    transition: transform 0.3s ease-out;
    background-color: ${theme.colors.main};
    z-index: 999;
    display: flex;
    flex-direction: column;
    
    
`;