import React, {Suspense} from 'react';

import { SRLWrapper } from "simple-react-lightbox";

import {
    BlumImage,
    ComponentsContent, ComponentsImage, ContentMargin,
    FirstContent, FirstMobileContent, GalleryContainer, GalleryImages,
    LeftSide,
    LeftSideText,
    LeftSideWrapper,
    MainPageContainer, MobileContentWrapper, MobileImage, RealizationsContent, RealizationSteps,
    RightSide, RowImagesContainer,
    Underline,
} from "./MainPage.style";

import Loader from '../../components/Loader/Loader';
import TextContent from "../../components/TextContent/TextContent";
import firstImage from '../../assets/images/firstSectionImage.jpg';
import HeaderImageContent from "../../components/HeaderImageContent/HeaderImageContent";
import Button from "../../components/Button/Button";
import ContactForm from "../../components/ContactForm/ContactForm";
import RealizationTile from "../../components/RealizationTile/RealizationTile";

// Assets
import blumImage from "../../assets/images/blum.png";
import component1 from "../../assets/images/components1.jpg"
import component2 from "../../assets/images/components2.jpg"
import contactIcon from "../../assets/svgIcons/constact.svg"
import createIcon from "../../assets/svgIcons/create.svg"
import montageIcon from "../../assets/svgIcons/montage.svg"
import {
    COMPONENTS_DESCRIPTION,
    COMPONENTS_TITLE,
    FIRST_CONTENT_DESCRIPTION,
    FIRST_CONTENT_TITLE,
    HEADER_BUTTONS_1,
    HEADER_BUTTONS_2,
    HEADER_BUTTONS_3,
    HEADER_BUTTONS_4,
    OUR_OFFER,
    PAGE_TITLE,
    SECOND_CONTENT,
    STEP_1_DESCRIPTION,
    STEP_1_TITLE,
    STEP_2_DESCRIPTION,
    STEP_2_TITLE,
    STEP_3_DESCRIPTION,
    STEP_3_TITLE
} from "../../utils/labels";
import {galleryArray, mainPageImagesId} from "../../utils/galleryArray";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import theme from "../../utils/theme";
import galleryOptions from "../../utils/galleryOptions";

const MainPage = () => {
    const { width } = useWindowDimensions();
    const mobileBreakpoint = +theme.size.tablet.replace('px', '');

    const filteredImages = galleryArray.filter((i) => mainPageImagesId.includes((i.id)));

    const elements = filteredImages.map((i) => {
        return (
            <a key={i.id} href={i.src} style={{margin: '1px 3px'}}>
                <img src={i.thumbnail} style={{width: '100%'}} alt={'Meble kuchenne'}/>
            </a>
        )
    })


    return (
        <MainPageContainer>
            <Suspense fallback={<Loader />}>
            {width > mobileBreakpoint ? (
                <FirstContent>
                    <LeftSide>
                        <LeftSideWrapper>
                            <LeftSideText>
                                {PAGE_TITLE}
                            </LeftSideText>
                            <Underline />
                            <Button variant={'primaryGray'} to={'/oferta'}>
                                {OUR_OFFER}
                            </Button>
                        </LeftSideWrapper>
                    </LeftSide>
                    <RightSide>
                        <HeaderImageContent
                            img={firstImage}
                            mainButton={HEADER_BUTTONS_1}
                            button2={HEADER_BUTTONS_2}
                            button3={HEADER_BUTTONS_3}
                            button4={HEADER_BUTTONS_4}
                        />
                    </RightSide>
                </FirstContent>
            ) : (
                <FirstMobileContent>
                    <MobileImage src={firstImage} />
                    <MobileContentWrapper>
                        <LeftSideText>
                            {PAGE_TITLE}
                        </LeftSideText>
                        <Underline />
                        <Button variant={'primaryGray'} to={'/oferta'}>
                            {OUR_OFFER}
                        </Button>
                    </MobileContentWrapper>
                </FirstMobileContent>
            )}

            <ContentMargin>
                <TextContent
                    title={FIRST_CONTENT_TITLE}
                    description={FIRST_CONTENT_DESCRIPTION}
                />
            </ContentMargin>

            <ContactForm />

            <ContentMargin>
                <TextContent
                    title={SECOND_CONTENT}
                />

                <GalleryContainer>
                    <SRLWrapper options={galleryOptions}>
                        <GalleryImages>
                            {elements}
                        </GalleryImages>
                    </SRLWrapper>
                </GalleryContainer>
            </ContentMargin>

            <ComponentsContent>
                <TextContent
                    title={COMPONENTS_TITLE}
                    description={COMPONENTS_DESCRIPTION}
                />
                <BlumImage src={blumImage} />
                <RowImagesContainer>
                    <ComponentsImage src={component1} />
                    <ComponentsImage src={component2} />
                </RowImagesContainer>
            </ComponentsContent>

            <RealizationsContent>
                <TextContent
                    title={'3 kroki do Twojej realizacji'}
                />
                <RealizationSteps>
                    <RealizationTile
                        img={contactIcon}
                        title={STEP_1_TITLE}
                        description={STEP_1_DESCRIPTION}
                    />
                    <RealizationTile
                        img={createIcon}
                        title={STEP_2_TITLE}
                        description={STEP_2_DESCRIPTION}
                    />
                    <RealizationTile
                        img={montageIcon}
                        title={STEP_3_TITLE}
                        description={STEP_3_DESCRIPTION}
                    />

                </RealizationSteps>

            </RealizationsContent>
            </Suspense>
        </MainPageContainer>
    )
}

export default MainPage;
