import React, {useEffect} from 'react';
import Iframe from 'react-iframe'
import HeaderBackgroundImage from "../../components/HeaderBackgroundImage/HeaderBackgroundImage";
import ContactForm from '../../components/ContactForm/ContactForm';
import {
    AddressWrapper,
    Bar,
    ContactContainer,
    DataRow,
    DataText, DataTextWrapper,
    DataTitle,
    DataWrapper,
    Tile,
    Wrapper,
    MapContainer
} from "./ContactPage.style";
import theme from "../../utils/theme";
import {CONTACT, DATA_COMPANY, DATA_CONTACT, NIP, REGON} from "../../utils/labels";
import {FaPhoneAlt, IoMdMail, MdLocationOn} from "react-icons/all";

const ContactPage = () => {

    useEffect(() => {
        document.title = 'Tom-dar - Kontakt | Meble skrojone na miarę | Trójmiasto';
    }, [])

    return (
        <ContactContainer>
            <HeaderBackgroundImage title={CONTACT.title} />
            <Wrapper>
                <Tile>
                    <DataTitle>{DATA_CONTACT}</DataTitle>
                    <Bar />
                    <DataWrapper>
                        <DataRow>
                            <MdLocationOn
                                style={{marginRight: theme.spacing.sm}}
                                color={theme.colors.main}
                                size={theme.fontSize.s}
                            />
                            <AddressWrapper>
                                <DataText>{CONTACT.address}</DataText>
                                <DataText>{CONTACT.city}</DataText>
                            </AddressWrapper>
                        </DataRow>
                        <DataRow>
                            <FaPhoneAlt
                                style={{marginRight: theme.spacing.sm}}
                                color={theme.colors.main}
                                size={theme.fontSize.s}
                            />
                            <DataTextWrapper>
                                <DataText>{CONTACT.phone}</DataText>
                            </DataTextWrapper>
                        </DataRow>
                        <DataRow>
                            <IoMdMail
                                style={{marginRight: theme.spacing.sm}}
                                color={theme.colors.main}
                                size={theme.fontSize.s}
                            />
                            <DataTextWrapper>
                                <DataText>{CONTACT.mail}</DataText>
                            </DataTextWrapper>
                        </DataRow>
                    </DataWrapper>
                </Tile>

                <Tile>
                    <DataTitle>{DATA_COMPANY}</DataTitle>
                    <Bar />
                    <DataWrapper>
                        <DataRow>
                            <DataText>{CONTACT.companyName}</DataText>
                        </DataRow>
                        <DataRow>
                            <DataText>{CONTACT.address}</DataText>
                        </DataRow>
                        <DataRow>
                            <DataText>{CONTACT.city}</DataText>
                        </DataRow>
                        <DataRow>
                            <DataText>{NIP}{' '}{CONTACT.nip}</DataText>
                        </DataRow>
                        <DataRow>
                            <DataText>{REGON}{' '}{CONTACT.regon}</DataText>
                        </DataRow>
                    </DataWrapper>
                </Tile>

            </Wrapper>

            <MapContainer>
                <Iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d74446.54727803271!2d18.441476069024855!3d54.33127272106963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd9fe90758920b%3A0x46ee1e6a1e38749f!2sTom-dar!5e0!3m2!1spl!2spl!4v1613923693082!5m2!1spl!2spl"
                    width="100%"
                    height="450"
                    style="border:0;"
                    allowFullScreen=""
                    loading="lazy">
                </Iframe>
            </MapContainer>

            <ContactForm />
        </ContactContainer>
    )
}

export default ContactPage;
