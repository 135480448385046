import React, {useEffect} from 'react';
import {REALIZATIONS} from "../../utils/labels";
import HeaderBackgroundImage from "../../components/HeaderBackgroundImage/HeaderBackgroundImage";
import ContactForm from "../../components/ContactForm/ContactForm";
import {galleryArray} from "../../utils/galleryArray";
import {GalleryContainer} from "./RealizationsPage.style";
import {SRLWrapper} from "simple-react-lightbox";
import galleryOptions from "../../utils/galleryOptions";
import {GalleryImages} from "../MainPage/MainPage.style";


const RealizationsPage = () => {

    useEffect(() => {
        document.title = 'Tom-dar - Realizacje | Meble skrojone na miarę | Trójmiasto';
    }, [])

    const images = galleryArray.map((i) => {
        return (
            <a key={i.id} href={i.src} style={{margin: '1px 3px'}}>
                <img src={i.thumbnail} style={{width: '100%'}} alt={'Meble kuchenne'}/>
            </a>
        )
    })

    return (
        <>
            <HeaderBackgroundImage title={REALIZATIONS} />
            <GalleryContainer>
                <SRLWrapper options={galleryOptions}>
                    <GalleryImages>
                        {images}
                    </GalleryImages>
                </SRLWrapper>
            </GalleryContainer>
            <ContactForm />
        </>
    )
}

export default RealizationsPage;


