import React from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";
import theme from "../utils/theme";

const CookiesCustom = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Akceptuje"
            cookieName="myAwesomeCookieName2"
            style={{
                background: theme.colors.main,
                opacity: 0.9,
                fontFamily: theme.fonts.main,
                fontSize: theme.fontSize.xs/1.5,
            }}
            buttonStyle={{
                color: theme.colors.foreground,
                fontSize: "13px",
                backgroundColor: theme.colors.secondary,
            }}
            expires={150}
        >
            Ta strona używa plików cookies w celu zapewnienia poprawnego i spersonalizowanego działania. Odwiedzając tę stronę, zgadzasz się na wykorzystywanie cookies.
        </CookieConsent>
    )
}

export default CookiesCustom;