import React from 'react';
import { Formik } from 'formik';
import {ContactImage, Container, FormWrapper, LeftSide, RightSide, Wrapper} from "./ContactForm.style";
import TextContent from "../TextContent/TextContent";
import * as emailjs from 'emailjs-com';
import {Input, Textarea} from "../Input";
import {
    FORM_LABEL_EMAIL,
    FORM_LABEL_MESSAGE,
    FORM_LABEL_NAME,
    FORM_LABEL_PHONE,
    FORM_SUBMIT,
    FROM_TITLE
} from "../../utils/labels";
import contactImg from '../../assets/images/contact.png';
import {SubmitButton} from "../../pages/ContactPage/ContactPage.style";

const ContactForm = () => {


    return (
        <Container>
            <TextContent
                title={FROM_TITLE}
                description={''}
                variant={'small'}
            />
            <Wrapper>
                <LeftSide>
                    <ContactImage src={contactImg} />

                </LeftSide>
                <RightSide>
                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            phone: '',
                            message: '',
                        }}
                        validate={values => {
                            const errors = {};
                            if (!values.email) {
                                errors.email = 'Wymagane!';
                            }
                            if (!values.name) {
                                errors.name = 'Wymagane!';
                            }
                            if (!values.message) {
                                errors.message = 'Wymagane!';
                            }
                            if (!values.phone) {
                                errors.phone = 'Wymagane!';
                            }
                            return errors;
                        }}
                        onSubmit={(values) => {
                            const templateParams = {
                                from_name: values.name,
                                from_email: values.email,
                                to_name: 'Tom-dar',
                                subject: 'tom-dar.pl - formularz kontaktowy',
                                message_html: values.message,
                                from_phone: values.phone,
                            };
                            emailjs.send(
                                'service_7njg8x7',
                                'template_j1l7dy3',
                                templateParams,
                                'user_6Kd4tNjFtEhfcDEW3Ad4N'
                            )
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <Input
                                    required
                                    placeholder={FORM_LABEL_NAME}
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <FormWrapper>
                                    <Input
                                        required
                                        smaller={true}
                                        placeholder={FORM_LABEL_EMAIL}
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    <Input
                                        required
                                        smaller={true}
                                        placeholder={FORM_LABEL_PHONE}
                                        type="tel"
                                        name="phone"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                    />
                                </FormWrapper>
                                <Textarea
                                    required
                                    rows={10}
                                    placeholder={FORM_LABEL_MESSAGE}
                                    type="text"
                                    name="message"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.message}
                                />

                                <SubmitButton
                                    type="submit"
                                    disabled={isSubmitting}
                                    send={isSubmitting}
                                >
                                    {!isSubmitting ? FORM_SUBMIT : 'Wysłano'}
                                </SubmitButton>
                            </form>
                        )}
                    </Formik>
                </RightSide>
            </Wrapper>

        </Container>
    );
};

export default ContactForm;
