import styled from 'styled-components';
import theme from "../utils/theme";

export const Input = styled.input`
    width: ${props => props.smaller ? 49 : 100}%;
    padding: ${theme.spacing.sm}px;
    border: none;
    background-color: ${theme.colors.background};
    margin-bottom: ${theme.spacing.xs}px;
    font-family: ${theme.fonts.main};
    font-size: ${theme.spacing.s}px;
    font-weight: ${theme.fontWeight.regular};  
    color: ${theme.colors.foreground};
    outline: none;
    border: ${props => props.valid ? '1px solid darkred' : 'none'};
`;

export const Textarea = styled.textarea`
    width: 100%;
    padding: ${theme.spacing.sm}px;
    border: none;
    background-color: ${theme.colors.background};
    margin-bottom: ${theme.spacing.xs}px;
    font-family: ${theme.fonts.main};
    font-size: ${theme.spacing.s}px;
    font-weight: ${theme.fontWeight.regular};  
    color: ${theme.colors.foreground};
    outline: none;
    resize: none;
`;

