import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import theme from "./utils/theme";

// Pages
import MainPage from "./pages/MainPage/MainPage";
import RealizationsPage from "./pages/RealizationsPage/RealizationsPage";
import OfferPage from "./pages/OfferPage/OfferPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Header from "./components/Header/Header";

// Components and styles
import GlobalStyles from './index.css';
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop";
import useWindowDimensions from "./hooks/useWindowDimensions";
import MobileHeader from "./components/MobileHeader/MobileHeader";
import CookiesCustom from "./components/Cookies";


const App = () => {
  const { width } = useWindowDimensions();
  const mobileBreakpoint = +theme.size.laptop.replace('px', '');
  return (
      <>
          <GlobalStyles />
          <Router>
              <CookiesCustom />
              <ScrollToTop />
              {width > mobileBreakpoint ? (<Header />) : (<MobileHeader/>)}

              <Switch>
                  <Route exact path={'/'} component={MainPage} />
                  <Route path={"/realizacje"} component={RealizationsPage} />
                  <Route path={"/oferta"} component={OfferPage} />
                  <Route path={"/kontakt"} component={ContactPage} />
                  <Route component={ErrorPage} />
              </Switch>

              <Footer />
          </Router>

      </>

  );
}

export default App;
