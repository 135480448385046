import R1 from '../assets/images/realizations/  (1).jpg';
import T1 from '../assets/images/thumbnails/1.jpg';

import R2 from '../assets/images/realizations/  (2).jpg';
import T2 from '../assets/images/thumbnails/2.jpg';

import R3 from '../assets/images/realizations/  (3).jpg';
import T3 from '../assets/images/thumbnails/3.jpg';

import R4 from '../assets/images/realizations/  (4).jpg';
import T4 from '../assets/images/thumbnails/4.jpg';

import R5 from '../assets/images/realizations/  (5).jpg';
import T5 from '../assets/images/thumbnails/5.jpg';

import R6 from '../assets/images/realizations/  (6).jpg';
import T6 from '../assets/images/thumbnails/6.jpg';

import R7 from '../assets/images/realizations/  (7).jpg';
import T7 from '../assets/images/thumbnails/7.jpg';

import R8 from '../assets/images/realizations/  (8).jpg';
import T8 from '../assets/images/thumbnails/8.jpg';

import R9 from '../assets/images/realizations/  (9).jpg';
import T9 from '../assets/images/thumbnails/9.jpg';

import R10 from '../assets/images/realizations/  (10).jpg';
import T10 from '../assets/images/thumbnails/10.jpg';

import R11 from '../assets/images/realizations/  (11).jpg';
import T11 from '../assets/images/thumbnails/11.jpg';

import R12 from '../assets/images/realizations/  (12).jpg';
import T12 from '../assets/images/thumbnails/12.jpg';

import R13 from '../assets/images/realizations/  (13).jpg';
import T13 from '../assets/images/thumbnails/13.jpg';

import R14 from '../assets/images/realizations/  (14).jpg';
import T14 from '../assets/images/thumbnails/14.jpg';

import R15 from '../assets/images/realizations/  (15).jpg';
import T15 from '../assets/images/thumbnails/15.jpg';

import R16 from '../assets/images/realizations/  (16).jpg';
import T16 from '../assets/images/thumbnails/16.jpg';

import R17 from '../assets/images/realizations/  (17).jpg';
import T17 from '../assets/images/thumbnails/18.jpg';

import R18 from '../assets/images/realizations/  (18).jpg';
import T18 from '../assets/images/thumbnails/19.jpg';

import R19 from '../assets/images/realizations/  (19).jpg';
import T19 from '../assets/images/thumbnails/20.jpg';

import R20 from '../assets/images/realizations/  (20).jpg';
import T20 from '../assets/images/thumbnails/21.jpg';

import R21 from '../assets/images/realizations/  (21).jpg';
import T21 from '../assets/images/thumbnails/22.jpg';

import R22 from '../assets/images/realizations/  (22).jpg';
import T22 from '../assets/images/thumbnails/23.jpg';

import R23 from '../assets/images/realizations/  (23).jpg';
import T23 from '../assets/images/thumbnails/24.jpg';

import R24 from '../assets/images/realizations/  (24).jpg';
import T24 from '../assets/images/thumbnails/25.jpg';

import R25 from '../assets/images/realizations/  (25).jpg';
import T25 from '../assets/images/thumbnails/26.jpg';

import R26 from '../assets/images/realizations/  (26).jpg';
import T26 from '../assets/images/thumbnails/27.jpg';

import R27 from '../assets/images/realizations/  (27).jpg';
import T27 from '../assets/images/thumbnails/28.jpg';

import R28 from '../assets/images/realizations/  (28).jpg';
import T28 from '../assets/images/thumbnails/29.jpg';

import R29 from '../assets/images/realizations/  (29).jpg';
import T29 from '../assets/images/thumbnails/30.jpg';

import R30 from '../assets/images/realizations/  (30).jpg';
import T30 from '../assets/images/thumbnails/31.jpg';

import R31 from '../assets/images/realizations/  (31).jpg';
import T31 from '../assets/images/thumbnails/32.jpg';

import R32 from '../assets/images/realizations/  (32).jpg';
import T32 from '../assets/images/thumbnails/33.jpg';

import R33 from '../assets/images/realizations/  (33).jpg';
import T33 from '../assets/images/thumbnails/34.jpg';

import R34 from '../assets/images/realizations/  (34).jpg';
import T34 from '../assets/images/thumbnails/35.jpg';

import R35 from '../assets/images/realizations/  (35).jpg';
import T35 from '../assets/images/thumbnails/36.jpg';

import R36 from '../assets/images/realizations/  (36).jpg';
import T36 from '../assets/images/thumbnails/37.jpg';

import R37 from '../assets/images/realizations/  (37).jpg';
import T37 from '../assets/images/thumbnails/38.jpg';

import R38 from '../assets/images/realizations/  (38).jpg';
import T38 from '../assets/images/thumbnails/39.jpg';

import R39 from '../assets/images/realizations/  (39).jpg';
import T39 from '../assets/images/thumbnails/40.jpg';

import R40 from '../assets/images/realizations/  (40).jpg';
import T40 from '../assets/images/thumbnails/41.jpg';

import R41 from '../assets/images/realizations/  (41).jpg';
import T41 from '../assets/images/thumbnails/42.jpg';

import R42 from '../assets/images/realizations/  (42).jpg';
import T42 from '../assets/images/thumbnails/43.jpg';

import R43 from '../assets/images/realizations/  (43).jpg';
import T43 from '../assets/images/thumbnails/44.jpg';

import R44 from '../assets/images/realizations/  (44).jpg';
import T44 from '../assets/images/thumbnails/45.jpg';

import R45 from '../assets/images/realizations/  (45).jpg';
import T45 from '../assets/images/thumbnails/46.jpg';


import R46 from '../assets/images/realizations/  (46).jpg';
import T46 from '../assets/images/thumbnails/47.jpg';

import R47 from '../assets/images/realizations/  (47).jpg';
import T47 from '../assets/images/thumbnails/48.jpg';

import R48 from '../assets/images/realizations/  (48).jpg';
import T48 from '../assets/images/thumbnails/49.jpg';

import R49 from '../assets/images/realizations/  (49).jpg';
import T49 from '../assets/images/thumbnails/50.jpg';

import R50 from '../assets/images/realizations/  (50).jpg';
import T50 from '../assets/images/thumbnails/51.jpg';

import R51 from '../assets/images/realizations/  (51).jpg';
import T51 from '../assets/images/thumbnails/52.jpg';

import R52 from '../assets/images/realizations/  (52).jpg';
import T52 from '../assets/images/thumbnails/53.jpg';

import R53 from '../assets/images/realizations/  (53).jpg';
import T53 from '../assets/images/thumbnails/54.jpg';

import R54 from '../assets/images/realizations/  (54).JPG';
import T54 from '../assets/images/thumbnails/55.jpg';

import R55 from '../assets/images/realizations/  (55).JPG';
import T55 from '../assets/images/thumbnails/56.jpg';

import R56 from '../assets/images/realizations/  (56).JPG';
import T56 from '../assets/images/thumbnails/57.jpg';

export const categories = {
    1: 'kitchens',
    2: 'wardrobes',
    3: 'bathrooms',
}

export const galleryArray = [
    {
        id: 2,
        thumbnail: T15,
        src: R15,
        categoryId: 1,
    },     {
        id: 3,
        thumbnail: T24,
        src: R24,
        categoryId: 1,
    },    {
        id: 4,
        thumbnail: T4,
        src: R4,
        categoryId: 2,
    },    {
        id: 5,
        thumbnail: T5,
        src: R5,
        categoryId: 3,
    },    {
        id: 6,
        thumbnail: T6,
        src: R6,
        categoryId: 1,
    },    {
        id: 7,
        thumbnail: T7,
        src: R7,
        categoryId: 2,
    },    {
        id: 8,
        thumbnail: T8,
        src: R8,
        categoryId: 2,
    },    {
        id: 9,
        thumbnail: T9,
        src: R9,
        categoryId: 1,
    },    {
        id: 10,
        thumbnail: T10,
        src: R10,
        categoryId: 1,
    },    {
        id: 11,
        thumbnail: T11,
        src: R11,
        categoryId: 1,
    },    {
        id: 12,
        thumbnail: T12,
        src: R12,
        categoryId: 1,
    },    {
        id: 13,
        thumbnail: T13,
        src: R13,
        categoryId: 1,
    },    {
        id: 14,
        thumbnail: T14,
        src: R14,
        categoryId: 1,
    },    {
        id: 15,
        thumbnail: T3,
        src: R3,
        categoryId: 1,
    },    {
        id: 16,
        thumbnail: T16,
        src: R16,
        categoryId: 1,
    },    {
        id: 17,
        thumbnail: T17,
        src: R17,
        categoryId: 1,
    },    {
        id: 18,
        thumbnail: T18,
        src: R18,
        categoryId: 1,
    },    {
        id: 19,
        thumbnail: T19,
        src: R19,
        categoryId: 1,
    },    {
        id: 20,
        thumbnail: T20,
        src: R20,
        categoryId: 1,
    },    {
        id: 21,
        thumbnail: T21,
        src: R21,
        categoryId: 1,
    },    {
        id: 22,
        thumbnail: T22,
        src: R22,
        categoryId: 1,
    },    {
        id: 23,
        thumbnail: T23,
        src: R23,
        categoryId: 1,
    },    {
        id: 24,
        thumbnail: T24,
        src: R24,
        categoryId: 1,
    },    {
        id: 25,
        thumbnail: T25,
        src: R25,
        categoryId: 1,
    },    {
        id: 26,
        thumbnail: T26,
        src: R26,
        categoryId: 1,
    },    {
        id: 27,
        thumbnail: T27,
        src: R27,
        categoryId: 1,
    },    {
        id: 28,
        thumbnail: T28,
        src: R28,
        categoryId: 1,
    },    {
        id: 29,
        thumbnail: T29,
        src: R29,
        categoryId: 1,
    },    {
        id: 30,
        thumbnail: T30,
        src: R30,
        categoryId: 1,
    },    {
        id: 31,
        thumbnail: T31,
        src: R31,
        categoryId: 1,
    },    {
        id: 32,
        thumbnail: T32,
        src: R32,
        categoryId: 1,
    },    {
        id: 33,
        thumbnail: T33,
        src: R33,
        categoryId: 1,
    },    {
        id: 34,
        thumbnail: T34,
        src: R34,
        categoryId: 1,
    },    {
        id: 35,
        thumbnail: T35,
        src: R35,
        categoryId: 1,
    },    {
        id: 36,
        thumbnail: T36,
        src: R36,
        categoryId: 1,
    },    {
        id: 37,
        thumbnail: T37,
        src: R37,
        categoryId: 1,
    },    {
        id: 38,
        thumbnail: T38,
        src: R38,
        categoryId: 1,
    },    {
        id: 39,
        thumbnail: T39,
        src: R39,
        categoryId: 1,
    },    {
        id: 40,
        thumbnail: T40,
        src: R40,
        categoryId: 1,
    },    {
        id: 41,
        thumbnail: T41,
        src: R41,
        categoryId: 1,
    },    {
        id: 42,
        thumbnail: T42,
        src: R42,
        categoryId: 1,
    },    {
        id: 43,
        thumbnail: T43,
        src: R43,
        categoryId: 1,
    },    {
        id: 44,
        thumbnail: T44,
        src: R44,
        categoryId: 1,
    },    {
        id: 45,
        thumbnail: T45,
        src: R45,
        categoryId: 1,
    },    {
        id: 46,
        thumbnail: T46,
        src: R46,
        categoryId: 1,
    },    {
        id: 47,
        thumbnail: T47,
        src: R47,
        categoryId: 1,
    },    {
        id: 48,
        thumbnail: T48,
        src: R48,
        categoryId: 1,
    },    {
        id: 49,
        thumbnail: T49,
        src: R49,
        categoryId: 2,
    },    {
        id: 50,
        thumbnail: T50,
        src: R50,
        categoryId: 1,
    },    {
        id: 51,
        thumbnail: T51,
        src: R51,
        categoryId: 1,
    },    {
        id: 52,
        thumbnail: T52,
        src: R52,
        categoryId: 1,
    },    {
        id: 53,
        thumbnail: T53,
        src: R53,
        categoryId: 1,
    },    {
        id: 54,
        thumbnail: T54,
        src: R54,
        categoryId: 1,
    },    {
        id: 55,
        thumbnail: T55,
        src: R55,
        categoryId: 1,
    },    {
        id: 56,
        thumbnail: T56,
        src: R56,
        categoryId: 1,
    },
]

export const mainPageImagesId = [2, 3, 6, 7, 9, 10, 14, 15, 17, 19, 20, 25, 29, 31, 35];