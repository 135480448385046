import styled from 'styled-components';
import theme from '../../utils/theme';

export const NavWrapper = styled.nav`
    display: flex;
    align-items: center;
  
    @media (${theme.device.laptop}) {
        justify-content: center;
        height: 100%;
    }
`

export const Ul = styled.ul`
    display: flex;
    flex-direction: row;
    list-style-type: none;

    @media (${theme.device.laptop}) {
        flex-direction: column;
    }
`;

export const NavItem = styled.li`
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.semiBold};
    font-size: ${theme.fontSize.s}px;
    margin-right: ${theme.spacing.xl*2}px;
    
    & > a {
      text-decoration: none;
      color: ${theme.colors.background};
      
      &::after {
        content: '';
        width: 0;
        height: 3px;
        display: block;
        background: ${theme.colors.background};
        transition: 300ms;

        @media (${theme.device.laptopL}) {
          height: 5px;
        }
      }
      
      &.active::after {
        width: 44px;

        @media (${theme.device.laptopL}) {
            width: 0;
        }

      }
      
      &:hover::after {
        width: 44px;

        @media (${theme.device.laptopL}) {
            width: 0;
        }
      }
      
    }

    @media (${theme.device.laptopL}) {
    font-size: ${theme.fontSize.xs}px;
    }

    @media (${theme.device.laptop}) {
        margin: 0;
        padding: ${theme.spacing.xl}px;
        font-size: ${theme.fontSize.l}px;
    }


    @media (${theme.device.mobileL}) {
        margin: 0;
        padding: ${theme.spacing.sm*1.5}px;
        font-size: ${theme.fontSize.m}px;
    }
`;
