import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {PrimaryGrayButton, PrimaryWhiteButton, RegularButton} from './Button.style';

const Button = ({ variant, children, ...props }) => {
    const { to } = props;

    const Component = useMemo(() => {
        switch (variant) {
            case 'regular':
                return RegularButton;
            case 'primaryGray':
                return PrimaryGrayButton;
            case 'primaryWhite':
                return PrimaryWhiteButton
            default:
                return PrimaryGrayButton;
        }
    }, [variant]);

    const content = useMemo(() => (
        <Component {...props}>
            {children}
        </Component>
    ), [props, children])

    return to ? (
        <Link {...props} style={{textDecoration: 'none'}}>
            {content}
        </Link>
    ) : (
        <>
            {content}
        </>
    );
};
Button.propTypes = {
    variant: PropTypes.oneOf(['primaryWhite', 'regular', 'primaryGray'])
}
export default Button;
