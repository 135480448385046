import React from 'react';
import {
    Buttons,
    Container,
    ImageHeader, ImageWrapper, MainButtonContainer, MainButtonText,
    OtherButtonContainer,
    OtherButtonText, Overlay,
    Square
} from "./HeaderImageContent.style";
import {useHistory} from "react-router-dom";

const MainButton = ({children}) => {
    return (
        <MainButtonContainer>
            <MainButtonText>
                {children}
            </MainButtonText>
        </MainButtonContainer>
    )
}

const OtherButton = ({children}) => {
    return (
        <OtherButtonContainer>
            <OtherButtonText>
                {children}
            </OtherButtonText>
        </OtherButtonContainer>
    )
}


const HeaderImageContent = ({img, mainButton, button2, button3, button4}) => {
    const history = useHistory();
    return (
        <Container>
            <Square />
            <ImageWrapper>
                <Overlay />
                <ImageHeader src={img} />
            </ImageWrapper>
            <Buttons onClick={() => history.push('/realizacje')}>
                <MainButton>{mainButton}</MainButton>
                <OtherButton>{button2}</OtherButton>
                <OtherButton>{button3}</OtherButton>
                <OtherButton>{button4}</OtherButton>
            </Buttons>
        </Container>
    )
}

export default HeaderImageContent;
