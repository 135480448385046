import SquareLoader from "react-spinners/SquareLoader";
import theme from "../../utils/theme";
import React from "react";
import styled from "styled-components";

const Loader = () => {
    return (
        <WrapperLoader>
            <SquareLoader loading={true} color={theme.colors.main} />
        </WrapperLoader>
    );
};

export default Loader;

export const WrapperLoader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.background};
`;