import React, {useEffect} from 'react';
import Button from "../../components/Button/Button";
import styled from 'styled-components';
import theme from "../../utils/theme";

const ErrorPage = () => {
    useEffect(() => {
        document.title = 'Tom-dar - 404 | Meble skrojone na miarę | Trójmiasto';
    }, [])

    return (
        <Container>
            <Text>404 nie odnaleziono strony</Text>
            <Button variant={'primaryGray'} to={'/'}>Przejdź na stronę główną</Button>
        </Container>
    )
}

export default ErrorPage;


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.xl*2}px;
    
`;

const Text = styled.h2`
    font-family: ${theme.fonts.main};
    font-size: ${theme.fontSize.l}px;
    font-weight: ${theme.fontWeight.bold};
    margin-bottom: ${theme.spacing.xl}px;
    color: ${theme.colors.foreground};

    @media (${theme.device.tablet}) {
        font-size: ${theme.fontSize.m}px;
    }
`;