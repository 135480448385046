import React, {useState, useEffect} from 'react';

import Logo from "../Logo/Logo";
import {Container, Phone, Row, SideMenu} from "./MobileHeader.style";
import {CONTACT} from "../../utils/labels";
import { Turn as Hamburger } from 'hamburger-react'
import theme from "../../utils/theme";
import Nav from "../Nav/Nav";


const MobileHeader = () => {
    const [isOpenSideMenu, setOpenSideMenu] = useState(false);

    useEffect(() => {
        if (isOpenSideMenu) {
            document.body.style.overflow = 'hidden';
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isOpenSideMenu]);

    return (
        <Container>

            <Phone href={`tel:${CONTACT.phone}`}>Zachęcamy do kontaktu {CONTACT.phone}</Phone>
            <Row>
                <Logo variant={'smaller'} />
                <Hamburger
                    size={20}
                    color={theme.colors.foreground}
                    toggled={isOpenSideMenu}
                    toggle={setOpenSideMenu}
                />
            </Row>

            <SideMenu active={isOpenSideMenu}>
                <Nav
                    closeNav={() => setOpenSideMenu(false)}
                />
            </SideMenu>

        </Container>
    )
};

export default MobileHeader;