import styled from "styled-components";
import theme from "../../utils/theme";

export const FooterContainer = styled.footer`
    width: 100%;
    background-color: ${theme.colors.secondary};
    padding: ${theme.spacing.sm}px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: ${theme.spacing.xl}px;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;

    @media (${theme.device.tablet}) {
        width: 98%;
    }
`;

export const LogoContainer = styled.div`
    margin: ${theme.spacing.xl}px 0;
    align-self: flex-start;

    @media (${theme.device.mobileL}) {
        margin: 0;
    }
`;


export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 ${theme.spacing.xl}px;
    margin-bottom: ${theme.spacing.xl*3}px;
  
    @media (${theme.device.laptop}) {
        margin-bottom: ${theme.spacing.xl}px;
    }

    @media (${theme.device.mobileL}) {
        flex-direction: column;
    }
`;

export const DescriptionText = styled.p`
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.fontSize.xs}px;
    color: ${theme.colors.foreground};

    @media (${theme.device.laptop}) {
        font-size: ${theme.fontSize.xs/1.3}px;
    }
`;

export const DescriptionTextStrong = styled(DescriptionText)`
    font-weight: ${theme.fontWeight.semiBold};
`;

export const Margin = styled.div`
    margin-top: ${theme.spacing.sm}px;
`;

export const MapContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NavText = styled(DescriptionText)`
    &:hover {
      text-decoration: underline;
    }
`;

export const Copyright = styled.h3`
    font-weight: ${theme.fontWeight.semiBold};
    font-family: ${theme.fonts.main};
    font-size: ${theme.fontSize.xs}px;
    text-align: center;
    color: ${theme.colors.foreground};

    @media (${theme.device.laptop}) {
        font-size: ${theme.fontSize.xs/1.3}px;
    }
`;

export const MadeBy = styled.div`
    align-self: flex-end;
    display: flex;
    margin-right: ${theme.spacing.sm}px;
    font-weight: ${theme.fontWeight.semiBold};
    font-family: ${theme.fonts.main};
    font-size: ${theme.fontSize.xs/1.5}px;
    color: ${theme.colors.main};

    @media (${theme.device.mobileL}) {
        align-self: center;
        margin: ${theme.spacing.xs}px 0;
    }
`;

export const LinkTo = styled.a`
    margin-left: 3px;
    color: ${theme.colors.main};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
`;