import React from 'react';
import {useHistory} from 'react-router-dom';
import {Container, LogoImage, LogoText} from "./Logo.style";

//Assets
import logo from '../../assets/images/logo.png';

const Logo = ({variant}) => {
    const history = useHistory();
    return (
      <Container onClick={() => history.push('/')}>
          <LogoImage src={logo} alt={'Logo'} variant={variant} />
          <LogoText variant={variant}>tom-dar</LogoText>
      </Container>

    );
}

export default Logo;
