import styled from 'styled-components';
import theme from "../../utils/theme";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    width: 80%;
    margin: 0 auto;
`;

export const Title = styled.h2`
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.semiBold};
    color: ${theme.colors.foreground};
    font-size: ${props => props.variant === 'small' ? theme.fontSize.m : theme.fontSize.xl}px;
    text-align: center;

    @media (${theme.device.laptop}) {
        font-size: ${props => props.variant === 'small' ? theme.fontSize.m : theme.fontSize.l}px;
    }

    @media (${theme.device.mobileL}) {
        font-size: ${props => props.variant === 'small' ? theme.fontSize.s : theme.fontSize.m}px;
    }

    @media (${theme.device.mobileM}) {
        font-size: ${props => props.variant === 'small' ? theme.fontSize.xs : theme.fontSize.s}px;
    }
`

export const Bar = styled.div`
    background-color: ${theme.colors.main};
    width: 150px;
    height: 8px;
    margin: ${theme.spacing.xl}px 0;

    @media (${theme.device.laptop}) {
        height: 6px;
    }
  
    @media (${theme.device.mobileM}) {
        margin: ${theme.spacing.xs}px 0;
    }
`


export const Description = styled.h2`
    font-family: ${theme.fonts.main};
    font-weight: ${theme.fontWeight.regular};
    color: ${theme.colors.foreground};
    font-size: ${theme.fontSize.xs}px;
    text-align: center;

    @media (${theme.device.mobileM}) {
      font-size: ${theme.fontSize.xs/1.2}px;
    }  
`
