import React from 'react';
import {NavLink} from "react-router-dom";
import {NavItem, NavWrapper, Ul} from "./Nav.style";
import theme from "../../utils/theme";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const navList = [
    { name: 'Start', path: '/', exact: true},
    { name: 'Realizacje', path: '/realizacje'},
    { name: 'Oferta', path: '/oferta'},
    { name: 'Kontakt', path: '/kontakt'},
]

const Nav = ({closeNav}) => {
    const mobileBreakpoint = +theme.size.laptop.replace('px', '');
    const { width } = useWindowDimensions();


    const menu = navList.map((item, index) => (
        <NavItem key={index}>
            <NavLink onClick={() => width < mobileBreakpoint && closeNav()} to={item.path} exact={item.exact ? item.exact : false}>{item.name}</NavLink>
        </NavItem>
    ))

    return (
        <NavWrapper>
            <Ul>
                {menu}
            </Ul>
        </NavWrapper>

    );
};

export default Nav;
